const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, isLoading: true };

    case 'LOGIN_USER':
      return { ...state, user: action.payload };

    case 'LOGIN_ERROR':
      return { ...state, errorMsg: action.payload };

    case 'LOGOUT':
      return { ...state, user: {} };
    default:
      throw new Error('No matching action found');
  }
};

export default authReducer;
