import axios from 'axios';
import { getAuthTimeZone } from '../common/helper';

const login = async () => {

  try {
    const userData = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL_USERDATA}/extension/user`,
      getAuthTimeZone()
    );
    const firstName = userData?.data?.first_name;
      const profileUrl = userData?.data?.profile_image_url;
      localStorage.setItem('username', firstName);
      localStorage.setItem('profileUrl', profileUrl);
      return { firstName, profileUrl }
  } catch (err) {
    console.log(err);
  }

};

const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('profileUrl');
}
export { login, logout };


