import React, { useEffect } from 'react';
import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styled, { ThemeProvider } from 'styled-components';

import GlobalStyles, { theme } from './styles/GlobalStyles';

import { useAuthContext } from './context';

import RequireAuth from './components/RequireAuth';
import Missing from './components/Missing';
import NavHeader from './components/NavHeader';
import Layout from './components/Layout';

import CreateJob from './pages/createJob';
import DisplayJobs from './pages/DisplayJobs/DisplayJobs';
import EditJob from './pages/EditJob';
import { login, logout } from './services/auth.services';


const StyledContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    width: 300px;
    top: 65px;
  }
  .Toastify__toast--error {
    border: 1px solid #d32e2a80;
    color: #d32e2a;
    background-color: #f9dfdf;
  }
  .Toastify__toast--success {
    background-color: #dff9ec;
    border: 1px solid rgba(0, 186, 136, 0.5);
    color: #00ba88;
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast {
    border-radius: 10px;
    margin: 0;
    min-height: 50px;
    justify-content: space-between;
    align-items: center;

    &.Toastify__toast-body {
      margin: 0;
    }
  }

  .Toastify__close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;
function App() {
  const { loginUser } = useAuthContext();

  const queryClient = new QueryClient();

  const search = useLocation();

  useEffect(() => {
    checkExtensionIsLoggedIn();
  }, []);


  function checkExtensionIsLoggedIn() {
    try {
      chrome.runtime.sendMessage(
        process.env.REACT_APP_EXTENSION_ID,
        { message: 'IS_EXTENSION_LOGGEDIN'},
        function (reply) {
          if (reply && reply !== '') {
            loginUser({ token : reply});
            localStorage.setItem('token', reply);
            setUserData(reply);
          } else {
            logout();
            window.open(process.env.REACT_APP_LOGIN_URL, '_self');
          }
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  const setUserData = async token => {
    const { firstName, profileUrl } = await login();  
      loginUser({ firstName, token, profileUrl });
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <QueryClientProvider client={queryClient}>
        <StyledContainer
          closeButton={true}
          position="top-center"
          autoClose={2000}
          hideProgressBar={true}
        />
        {search.pathname !== '/jd' && <NavHeader />}
        <Routes>
          <Route element={<Layout />}>
            <Route element={<RequireAuth />}>
              <Route path="/" element={<DisplayJobs />} />
              <Route exact path="/create-job" element={<CreateJob />} />
              <Route path="/edit-job/:jobId" element={<EditJob />}>
                <Route path="" element={<CreateJob />} />
              </Route>
            </Route>

            {/* Catch all */}
            <Route path="*" element={<Missing />} />
          </Route>
        </Routes>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
