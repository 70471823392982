import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../context';

const RequireAuth = () => {
  const location = useLocation();
  const { user } = useAuthContext();

  return user?.token ? <Outlet /> : <Navigate to="/" state={{ from: location }} replace />;
};

export default RequireAuth;
