import JobDescription from './JobDescription';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.section`
  display: grid;
  overflow: hidden auto;
  /* min-height: 705px; */
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
  padding-bottom: 1rem;
  /* min-height: 705px; */

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const JobsList = ({ jobsList, activeTab, fetchJobs }) => {
  return (
    <Wrapper>
      {jobsList.map(job => (
        <JobDescription
          key={job._id}
          {...job}
          job={job}
          activeTab={activeTab}
          fetchTemplates={fetchJobs}
        />
      ))}
    </Wrapper>
  );
};

JobsList.propTypes = {
  jobsList: PropTypes.array,
  activeTab: PropTypes.bool,
  fetchJobs: PropTypes.func,
};

export default JobsList;
