import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Flex, HR } from '../../../styles/Flex.styled';
import {
  MapIcon,
  CopyTemplate,
  DeleteTemplate,
  ActiveJobLogo,
  ShareLogo,
} from '../../../components/icons';
import PropTypes from 'prop-types';
import { useQueryClient, useMutation } from 'react-query';
import Menu from '../../../assets/icons/menu.svg';
import OptionModal from '../../../components/OptionModal';
import { createJob, deleteJob, updateJD } from '../../../services/jobs.services';
import ConfirmationCard from '../../../common/ConfirmationCard';
import { useNavigate } from 'react-router-dom';
import ShareJd from '../../../components/shareJd';

const CardTitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
  color: #14142a;
  overflow: hidden;
  -webkit-line-clamp: 2;
  max-width: 93%;
  text-overflow: ellipsis;
  line-height: 24px;
  flex: 9;
  /* height: 46px; */
`;

const ShortDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${({ color, theme }) => color || theme.grey1};
  margin: 0;
  height: 40px;
`;

const LongDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ color, theme }) => color || theme.grey1};
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0;
  height: 60px;
`;

const Para = styled.p`
  font-weight: 300;
  color: ${({ color, theme }) => color || theme.grey1};
  font-size: 1rem;
  margin: 0;
  line-height: 1.5rem;
`;

const CardHeader = styled.div`
  /* flex-grow: 0; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 45px;
`;
const ImageWrapper = styled.div`
  flex: 1;
  cursor: pointer;
  opacity: 0.6;
  width: 20px;
  height: 16px;
  color: #4e4b66;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
`;

const CardFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* border-top: 1px solid #d9dbe9; */
  /* padding: 16px 5px 0px 5px; */
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
`;

const SharedJdWrapper = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #4e4b66;
  display: flex;
  cursor: pointer;
`;

const Created = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #4e4b66;
  opacity: 0.8;
`;

const CardBody = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  /* height: 210px; */
  cursor: pointer;
`;

const Card = styled.article`
  position: relative;
  background: #ffffff;
  box-shadow: 0px 1px 16px rgba(201, 210, 226, 0.4);
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
  padding: 24px;
  /*overflow: hidden;
  text-overflow: ellipsis;*/
  /* height: fit-content; */
  /* height: '330px'; */
`;

const ShareContent = styled.p`
  margin: 0;
  padding-left: 10px;
`;

const JobDescription = ({
  jobTitle,
  job,
  _id,
  shortDescription,
  activeTab,
  createdUserName,
  location,
  detailedDescription,
  allowCandidatesToApply,
  fetchTemplates,
}) => {
  const [showActionModel, setShowActionModel] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [confirmationCardData, setConfirmationCardData] = useState({});

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const editJD = () => {
    navigate(`/edit-job/${_id}`);
  };

  const createDuplicate = async duplicateData => {
    await duplicateJobMutation.mutate(duplicateData);
  };

  const duplicateJobMutation = useMutation(createJob, {
    onSuccess: () => queryClient.invalidateQueries('job'),
  });

  const deleteJobMutation = useMutation(deleteJob, {
    onSuccess: () => queryClient.invalidateQueries('delete'),
  });

  const updateJobMutation = useMutation(
    ({ jobId, jdObj }) => {
      return updateJD({ jobId, jdObj });
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['job']),
    }
  );

  const [showShareJd, setShowShareJd] = useState(false);

  const duplicateTemplate = async () => {
    const { _id, ...rest } = job;

    const data = {
      ...rest,
      jobTitle: '',
      _id,
    };

    createDuplicate(data);
  };

  const deleteTemplate = async () => {
    await deleteJobMutation.mutate(_id);
  };

  const applyForJob = async () => {
    const jdObj = {
      ...job,
      allowCandidatesToApply: !allowCandidatesToApply,
      imageUrl: 'dummy',
    };
    const jobId = _id;
    await updateJobMutation.mutate({ jobId, jdObj });
  };

  useEffect(() => {
    // Context updated of the AppToaster

    if (
      (duplicateJobMutation?.data?.status ||
        deleteJobMutation?.data?.status ||
        updateJobMutation?.data?.status) === 201
    ) {
      setShowDeletePopup(false);
      fetchTemplates();
      setShowActionModel(false);
    }
  }, [
    duplicateJobMutation?.data?.status,
    deleteJobMutation?.data?.status,
    updateJobMutation?.data?.status,
  ]);

  const openDeletePopup = () => {
    const deleteData = {
      title: 'Delete the JD?',
      description: `Are you sure you want to delete the ${jobTitle} JD?`,
      cancelHandler: () => setShowDeletePopup(false),
      actionHandler: deleteTemplate,
      btnText: 'Delete',
    };
    setConfirmationCardData(deleteData);
    setShowActionModel(false);
    setShowDeletePopup(true);
  };

  const openStatusPopup = () => {
    const deleteData = {
      title: activeTab ? 'Move JD to Inactive?' : 'Move JD to Active',
      description: `Are you sure you want to ${activeTab ? 'stop' : 'start'
        } hiring for this ${jobTitle}?`,
      cancelHandler: () => setShowDeletePopup(false),
      actionHandler: applyForJob,
      btnText: 'Move',
    };
    setConfirmationCardData(deleteData);
    setShowActionModel(false);
    setShowDeletePopup(true);
  };

  const templateActionOptions = [
    {
      icon: <CopyTemplate />,
      label: 'Duplicate template',
      onClick: duplicateTemplate,
      toHide: activeTab === false || job.isOriginalJD === false ? true : false,
    },
    {
      icon: <ActiveJobLogo />,
      label: activeTab ? 'Disable actively hiring' : 'Enable active hiring',
      onClick: openStatusPopup,
    },
    {
      icon: <DeleteTemplate />,
      label: 'Delete template',
      onClick: openDeletePopup,
    },
  ];

  const openModal = () => {
    setShowActionModel(!showActionModel);
  };

  const jdId = jobTitle.split(' ').join('-');

  return (
    <>
      {showDeletePopup && <ConfirmationCard {...confirmationCardData} />}
      {showShareJd && (
        <ShareJd title={jobTitle} jobId={_id} closeHandler={() => setShowShareJd(false)} />
      )}
      <Card>
        <CardHeader>
          <CardTitle>{jobTitle}</CardTitle>
          <ImageWrapper id={`${jdId}-popUp`} onClick={openModal}>
            <img src={Menu} />
          </ImageWrapper>
        </CardHeader>
        <CardBody id={jdId} onClick={editJD}>
          <ShortDescription>{shortDescription}</ShortDescription>
          <Flex direction="row nowrap" gap="0.5rem" ai="center" jc="unset">
            <MapIcon />
            <Para>{location}</Para>
          </Flex>
          <LongDescription
            className="longDescription"
            dangerouslySetInnerHTML={{ __html: detailedDescription }}></LongDescription>
        </CardBody>
        <HR margin=".25rem 0" backgroundColor="#D9DBE9" />

        <CardFooter>
          <SharedJdWrapper onClick={() => setShowShareJd(true)}>
            <ShareLogo />
            <ShareContent>Share JD</ShareContent>
          </SharedJdWrapper>
          <Created>Created by {createdUserName}</Created>
        </CardFooter>
        {showActionModel && (
          <OptionModal
            options={templateActionOptions}
            closeOptionsDropdown={() => {
              setShowActionModel(false);
            }}
          />
        )}
      </Card>
    </>
  );
};

JobDescription.propTypes = {
  job: PropTypes.object.isRequired,
  jobTitle: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  location: PropTypes.string,
  detailedDescription: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
  fetchTemplates: PropTypes.func.isRequired,
  allowCandidatesToApply: PropTypes.bool,
  createdUserName: PropTypes.string,
  activeTab: PropTypes.bool,
};

export default JobDescription;
