import axios from 'axios';
import { getAuthHeader } from './auth.Header';

const jobsAPI = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: { 'Content-Type': 'application/json' },
});

jobsAPI.interceptors.request.use(config => {
  if (config['Content-Type'] === 'multipart/form-data') {
    config.headers['Content-Type'] = 'multipart/form-data';
  }
  const token = localStorage.getItem('token');

  config.headers.Authorization = token ? `Bearer ${token}` : '';

  return config;
});

const createJob = async job => {
  try {
    const result = await jobsAPI.post('/create-jd', job);
    return result;
  } catch (error) {
    console.log('Error in Create JD', error);
    return error;
  }
};

const getAllJobs = async (name, limit, skip, isActive) => {
  try {
    const result = await jobsAPI.get(
      `/list-all-jd?name=${name}&limit=${limit}&skip=${skip}&isActive=${isActive}`
    );

    return result.data;
  } catch (error) {
    console.log(error);
  }
};

const getSingleJob = async id => {
  return await jobsAPI.get(`/getJD?_id=${id}`);
};

const uploadJD = async file => {
  return await jobsAPI.post('/upload-JD', file);
};

const deleteJob = async value => {
  try {
    const result = await jobsAPI.post(
      '/deleteJD',
      {
        _id: value,
      },
      getAuthHeader()
    );

    return result;
  } catch (error) {
    console.log('Error in Delete Jd', error);
    return error;
  }
};
const updateJD = async ({ jobId, jdObj }) => {
  return await jobsAPI.post(`/update-jd?_id=${jobId}`, jdObj);
};

// Public Routes or Third Party APIs
const getLogo = async name => {
  try {
    const res = await axios.get(
      `https://autocomplete.clearbit.com/v1/companies/suggest?query=${name}`
    );
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

const landingPage = async jobId => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/preview?_id=${jobId}`);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Gets the JD page link for the given parameters
 * @param {String} jdId - JD id
 * @param {String} candidateName - Candidate name
 * @param {String} candidateImageUrl - Candidate image url (optional)
 * @returns String
 */
const getJdLink = async (jdId, candidateName, candidateImageUrl = '') => {
  if (!jdId || !candidateName) return '';
  try {
    const response = await jobsAPI.post(`/generate-jd-image`, {
      _id: jdId,
      candidateName,
      ...(candidateImageUrl && {
        candidateImage: candidateImageUrl,
      }),
    });
    if (!response?.data?._id) return '';
    return `${process.env.REACT_APP_BACKEND_URL}/preview/jd?id=${response?.data?._id}`;
  } catch (error) {
    console.log(error);
    return '';
  }
};

const checkIfNameExist = async jobTitle => {
  try {
    const result = await jobsAPI.post('/name-exists', {
      jobTitle: jobTitle,
    });
    return result?.data;
  } catch (error) {
    console.log('Error in checking name of Jd', error);
    return error;
  }
};

export {
  createJob,
  getAllJobs,
  getSingleJob,
  uploadJD,
  deleteJob,
  updateJD,
  getLogo,
  landingPage,
  checkIfNameExist,
  getJdLink,
};
